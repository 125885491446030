/** @jsx jsx */
import { jsx, Styled, Flex, Box } from 'theme-ui';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/layout';
import { getTranslate } from 'react-localize-redux';
import { hideAllNotifications, showNotification, SEVERITY } from '../state/notifications';
import * as yup from 'yup';
import FormField from '../components/FormField';
import { Form, Field } from 'formik';
import RadioFieldGroup from '../components/RadioFieldGroup';
import RadioField from '../components/RadioField';
import { Container, Select, Tooltip } from '../components';
import { withFormik } from 'formik';
import RecaptchaButton from '../components/RecaptchaButton';
import * as api from '../utils/api';
import { handleFormValidationError } from '../utils/formError';
import { locNavigate } from '../state/session';
import Spinner from '../components/Spinner';
import * as queryString from 'query-string';
import testLogger from '../components/test-logger';
import { deviceDetect } from 'react-device-detect';
import * as analytics from '../utils/analytics';
import { InfoIcon } from '../components/Icon';
import Accordion from '../components/Accordion';

const ParcelCancellationFormSchema = translate =>
  yup.object().shape({
    identifierType: yup.string().required(translate('parcelCancellationForm.requiredField')),
    identifier: yup.string().required(translate('parcelCancellationForm.requiredField')),
    name: yup.string().required(translate('parcelCancellationForm.requiredField')),
    phoneNumber: yup.string().required(translate('parcelCancellationForm.requiredField')),
    email: yup
      .string()
      .required(translate('parcelCancellationForm.requiredField'))
      .email(translate('parcelCancellationForm.message.invalidEmail')),
    paymentMethod: yup.string().required(translate('parcelCancellationForm.requiredField')),
    cancellationReason: yup.string().required(translate('parcelCancellationForm.requiredField')),
    description: yup.string(),
    bankAccountNo: yup.string(),
    recaptcha: yup.string().required(),
  });

const ParcelCancellationForm = ({
  translate,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  errors,
  touched,
  nocaptcha,
  values,
}) => {
  return (
    <Form
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <RadioFieldGroup id="identifierType" label="">
        <Field
          component={RadioField}
          name="identifierType"
          id="shipmentNumber"
          label={translate('parcelCancellationForm.fillShipmentNumber')}
        />
        {values.identifierType === 'shipmentNumber' ? (
          <div sx={{ ml: 4, mt: 3 }}>
            <FormField
              sx={{ maxWidth: '350px' }}
              name="identifier"
              label={translate('parcelCancellationForm.shipmentNumber')}
            />
          </div>
        ) : null}
        <Field
          component={RadioField}
          name="identifierType"
          id="orderOrRefNumber"
          label={translate('parcelCancellationForm.fillOrderOrRefNumber')}
        />
        {values.identifierType === 'orderOrRefNumber' ? (
          <div sx={{ ml: 4, mt: 3 }}>
            <FormField
              sx={{ maxWidth: '350px' }}
              name="identifier"
              label={translate('parcelCancellationForm.orderOrRefNumber')}
            />
          </div>
        ) : null}
      </RadioFieldGroup>
      <Box sx={{ bg: 'blueLighter', px: 3, py: 1, borderRadius: 1, mt: 3, mb: 4 }}>
        <p>{translate('parcelCancellationForm.text3')}</p>
      </Box>
      <FormField sx={{ maxWidth: '550px' }} name="name" label={translate('parcelCancellationForm.fields.name')} />
      <FormField
        sx={{ maxWidth: '550px' }}
        name="phoneNumber"
        label={translate('parcelCancellationForm.fields.phoneNumber')}
      />
      <FormField sx={{ maxWidth: '550px' }} name="email" label={translate('parcelCancellationForm.fields.email')} />
      <RadioFieldGroup
        sx={{ maxWidth: '550px' }}
        id="paymentMethod"
        label={translate('parcelCancellationForm.fields.paymentMethod.title')}
        error={errors?.paymentMethod}
      >
        <Field
          component={RadioField}
          name="paymentMethod"
          id={translate('parcelCancellationForm.fields.paymentMethod.mobilePay')}
          label={translate('parcelCancellationForm.fields.paymentMethod.mobilePay')}
        />
        <Field
          component={RadioField}
          name="paymentMethod"
          id={translate('parcelCancellationForm.fields.paymentMethod.applePay')}
          label={translate('parcelCancellationForm.fields.paymentMethod.applePay')}
        />
        <Field
          component={RadioField}
          name="paymentMethod"
          id={translate('parcelCancellationForm.fields.paymentMethod.netbank')}
          label={translate('parcelCancellationForm.fields.paymentMethod.netbank')}
        />
        <Field
          component={RadioField}
          name="paymentMethod"
          id={translate('parcelCancellationForm.fields.paymentMethod.visa')}
          label={translate('parcelCancellationForm.fields.paymentMethod.visa')}
        />
        <Field
          component={RadioField}
          name="paymentMethod"
          id={translate('parcelCancellationForm.fields.paymentMethod.mastercard')}
          label={translate('parcelCancellationForm.fields.paymentMethod.mastercard')}
        />
      </RadioFieldGroup>
      <FormField
        sx={{ maxWidth: '550px' }}
        as={Select}
        name="cancellationReason"
        label={translate('parcelCancellationForm.fields.cancellationReason.title')}
      >
        <option value={''}>{translate('parcelCancellationForm.fields.defaultSelection')}</option>
        <option value={translate('parcelCancellationForm.fields.cancellationReason.noNeed')}>
          {translate('parcelCancellationForm.fields.cancellationReason.noNeed')}
        </option>
        <option value={translate('parcelCancellationForm.fields.cancellationReason.doubleCharge')}>
          {translate('parcelCancellationForm.fields.cancellationReason.doubleCharge')}
        </option>
        <option value={translate('parcelCancellationForm.fields.cancellationReason.noConfirmationOrActivation')}>
          {translate('parcelCancellationForm.fields.cancellationReason.noConfirmationOrActivation')}
        </option>
        <option value={translate('parcelCancellationForm.fields.cancellationReason.technicalIssue')}>
          {translate('parcelCancellationForm.fields.cancellationReason.technicalIssue')}
        </option>
        <option value={translate('parcelCancellationForm.fields.cancellationReason.other')}>
          {translate('parcelCancellationForm.fields.cancellationReason.other')}
        </option>
      </FormField>
      <FormField
        sx={{ maxWidth: '550px' }}
        name="description"
        label={translate('parcelCancellationForm.fields.description')}
      />
      <Accordion
        variant="faq"
        title={translate('parcelCancellationForm.fields.paidViaAlandsbankenOrSPankki')}
        sx={{ bg: 'white', border: '2px solid', borderColor: 'border', borderRadius: '8px', px: 3, py: 2, outline: 0 }}
        disableOnKeyDown={true}
      >
        <FormField
          sx={{ maxWidth: '550px' }}
          name="bankAccountNo"
          label={translate('parcelCancellationForm.fields.bankAccountNo')}
        />
      </Accordion>
      <RecaptchaButton
        buttonText="parcelCancellationForm.send"
        translate={translate}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        nocaptcha={nocaptcha}
        errors={errors}
        disabled={!Object.keys(touched).length || Object.keys(errors).filter(e => e != 'recaptcha').length}
      />
    </Form>
  );
};

const ParcelCancellationFormik = withFormik({
  mapPropsToValues: ({ vo }) =>
    Object.assign({
      identifierType: vo.identifierType || 'shipmentNumber',
      identifier: vo.identifier || '',
      name: vo.name || '',
      phoneNumber: vo.phoneNumber || '',
      email: vo.email || '',
      paymentMethod: vo.paymentMethod || '',
      cancellationReason: vo.cancellationReason || '',
      description: vo.description || '',
      bankAccountNo: vo.bankAccountNo || '',
      recaptcha: vo.recaptcha || '',
    }),
  validationSchema: ({ translate }) => {
    return ParcelCancellationFormSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'ParcelCancellationInputForm',
})(ParcelCancellationForm);

const ParcelCancellationFormPage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const [vo, setVO] = useState({});
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { nocaptcha } = queryParams;

  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      setShowSpinner(true);
      dispatch(hideAllNotifications());
      try {
        const values = { ...vo, identifierType: translate(`parcelCancellationForm.${vo.identifierType}`) };
        await api.sendParcelCancellationForm({ ...values, locale });
        dispatch(
          locNavigate('/thank-you', '', {
            state: {
              message: translate('parcelCancellationForm.message.success'),
              noDefault: true,
            },
          })
        );

        setShowSpinner(false);
        setSubmitting(false);
      } catch (error) {
        testLogger.log('sendParcelCancellationForm:FAILED ' + error + ', ' + JSON.stringify(deviceDetect()));
        setShowSpinner(false);
        setSubmitting(false);
        if (handleFormValidationError(error, dispatch, translate)) {
          return;
        }
        console.error(error);
        dispatch(showNotification('parcelCancellationForm.message.failed', SEVERITY.ERROR));
      }
      setVO({});
      window && window.scrollTo(0, 0);
    },
    [dispatch, locale, translate]
  );
  const formProps = { vo, onSubmit, translate, nocaptcha };

  return (
    <Layout
      title={translate('parcelCancellationForm.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container
        sx={{
          maxWidth: 1024,
          p: [3, 4],
        }}
      >
        <Styled.h1>{translate('parcelCancellationForm.title')}</Styled.h1>
        <p>{translate('parcelCancellationForm.text1')}</p>
        <p>{translate('parcelCancellationForm.text2')}</p>
        <Flex sx={{ flexDirection: 'column', maxWidth: '400px' }}>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <p>{translate('parcelCancellationForm.tooSmallParcel')}</p>
            <div sx={{ mt: 3 }}>
              <Tooltip
                placement="right"
                tooltip={
                  <div>
                    <p sx={{ color: 'white' }}>{translate('parcelCancellationForm.tooltip1')}</p>
                  </div>
                }
              >
                <InfoIcon sx={{ ml: 2, display: 'inline-block', verticalAlign: 'bottom' }} />
              </Tooltip>
            </div>
          </Flex>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <p>{translate('parcelCancellationForm.parcelLost')}</p>
            <div sx={{ mt: 3 }}>
              <Tooltip
                placement="right"
                tooltip={
                  <div>
                    <p sx={{ color: 'white' }}>{translate('parcelCancellationForm.tooltip2')}</p>
                  </div>
                }
              >
                <InfoIcon sx={{ ml: 2, display: 'inline-block', verticalAlign: 'bottom' }} />
              </Tooltip>
            </div>
          </Flex>
        </Flex>
        <ParcelCancellationFormik key={locale} {...formProps} />
        {showSpinner && <Spinner size="medium" position="fixed" />}
      </Container>
    </Layout>
  );
};

export default ParcelCancellationFormPage;
